export class ExportUtils {
    public static downloadFromBlob = (blob: Blob, fileName?: string) => {
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        if (fileName !== undefined) {
            anchor.setAttribute('download', fileName);
        }
        anchor.href = url;
        anchor.target = '_blank';
        anchor.click();
        window.URL.revokeObjectURL(url);
    }
}