import React, {Fragment, FunctionComponent, useEffect, useState} from "react";
import {PageTemplate} from "../../common/page-template/PageTemplate";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    ActivationFeatureItem,
    PropData,
    useGetApiV1ActivationByActivationIdQuery, useGetApiV1UserMeQuery,
    usePutApiV1LicenseMutation
} from "../../../api/CloudLicensesManagerApi";
import {Alert, Box, Button, Snackbar, Stack} from "@mui/material";
import {ActivationInfo} from "./ActivationInfo";
import {
    ProcessStatusNotification,
    toProcessStatus
} from "../../common/process-status-notification/ProcessStatusNotification";
import {ErrorMessage} from "../../common/error-message/ErrorMessage";
import {DottedListSkeleton} from "../../common/skeleton/DottedListSkeleton";
import {LicensePropsEditor} from "./LicensePropsEditor";
import {SignatureEditor} from "./SignatureEditor";
import {FeaturesEditor} from "./FeaturesEditor";
import {NotesEditor} from "./NotesEditor";
import {HardwareIdEditor} from "./HardwareIdEditor";
import {Loader} from "../../common/loader/Loader";
import {Authorizations} from "../../../book/Authorizations";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export const ActivationDetails: FunctionComponent = () => {
    const {activationId} = useParams();
    const {t} = useTranslation();
    const [state, setState] = useState<ActivationDetailsState>({
        signatures: [],
        features: [],
        licenseProps: [],
        propsErrors: false,
        notes: "",
        hardwareId: "",
        openCopyActivation: false
    });
    const {
        data: activationData,
        isLoading: isActivationLoading,
        isError: isActivationError
    } = useGetApiV1ActivationByActivationIdQuery({
        activationId: activationId!
    });
    const [updateLicense, {
        data: updateLicenseData,
        isLoading: isUpdateLicenseLoading,
        error: updateLicenseError,
        reset: resetUpdateLicense
    }] = usePutApiV1LicenseMutation();
    const {data: userMe, isLoading: isMeLoading} = useGetApiV1UserMeQuery(undefined)

    useEffect(() => {
        setState(state => ({
            ...state,
            signatures: activationData?.payload?.activation?.signatures ?? [],
            features: activationData?.payload?.features ?? [],
            licenseProps: activationData?.payload?.props ?? [],
            notes: activationData?.payload?.activation?.notes ?? "",
            hardwareId: activationData?.payload?.activation?.hardwareId ?? ""
        }))
    }, [activationData?.payload?.activation])

    if (!userMe || isMeLoading) {
        return <Loader message={t("Loading page, please wait...")}/>;
    }

    if (userMe.error) {
        return <ErrorMessage message={t("Error while loading page, please try again later")}/>;
    }

    const authorizations = userMe.payload!.authorizations;

    const ButtonsBox = () => {
        if (authorizations!.includes(Authorizations.ActivationEdit)) {
            return <Box sx={{overflow: 'hidden'}}>
                <Button variant="contained"
                        color="primary"
                        disabled={state.propsErrors}
                        sx={{marginTop: '2em', float: 'right'}}
                        onClick={() => {
                            updateLicense({
                                updateLicenseRequest: {
                                    activationId: activationId,
                                    features: state.features,
                                    signatures: state.signatures,
                                    props: state.licenseProps,
                                    notes: state.notes,
                                    hardwareId: state.hardwareId
                                }
                            })
                        }}>
                    {t("Save")}
                </Button>
            </Box>;
        }

        return null;
    };

    const PageTitle = () => <Fragment>
        {t("Activation - ")}
        {isActivationLoading && '---'}
        {!isActivationLoading && <Fragment>
            {activationData?.payload?.activation?.activationId}

            {!!activationData?.payload?.activation?.activationId &&
                <IconButton title={t("Copy activation ID")}
                            onClick={async () => {
                                setState(state => ({...state, openCopyOrder: true}));
                                await navigator.clipboard.writeText(activationData!.payload!.activation!.activationId!);
                            }}>
                    <ContentCopyIcon fontSize="small"/>
                </IconButton>}
        </Fragment>}
    </Fragment>;

    return <PageTemplate pageTitle={<PageTitle/>} showBackButton>
        {isActivationError && <ErrorMessage
            message={t("Error while load order, please check order id and retry.")}/>}

        {isActivationLoading && <DottedListSkeleton rowCount={7}/>}

        {Boolean(activationData?.payload?.activation) &&
            <ActivationInfo activation={activationData?.payload?.activation!} product={activationData?.payload?.product}/>}

        <ButtonsBox/>

        <Stack spacing={2}>
            <NotesEditor value={(state.notes ?? "")}
                         setValue={(newValues: string) => {
                             setState({
                                 ...state,
                                 notes: newValues
                             });
                         }}/>

            <HardwareIdEditor value={(state.hardwareId ?? "")}
                              setValue={(newValue: string) => {
                                  setState({
                                      ...state,
                                      hardwareId: newValue
                                  });
                              }}/>

            <LicensePropsEditor licenseProps={state.licenseProps}
                                onPropsChange={newLicenseProps => {
                                    setState(state => ({...state, licenseProps: newLicenseProps}));
                                }}
                                onErrorChange={(error) => {
                                    setState(state => ({...state, propsErrors: error}));
                                }}/>

            <SignatureEditor values={(state.signatures ?? [])}
                             setValue={(newValues: string[]) => {
                                 setState({
                                     ...state,
                                     signatures: newValues
                                 });
                             }}/>

            <FeaturesEditor features={state.features}
                            isLoading={isActivationLoading}
                            onFeaturesChange={(features: ActivationFeatureItem[]) => {
                                setState({
                                    ...state,
                                    features: features
                                });
                            }}/>
        </Stack>

        <ButtonsBox/>

        <ProcessStatusNotification
            status={toProcessStatus(isUpdateLicenseLoading, Boolean(updateLicenseData?.payload), Boolean(updateLicenseError))}
            onClose={() => resetUpdateLicense()}
            onLoadingMessage={t("Updating license please wait…")}
            onSuccessMessage={t("License updates successfully")}
            onErrorMessage={t("Updating license failed")}/>

        <Snackbar open={state.openCopyActivation}
                  autoHideDuration={3000}
                  onClose={() => setState(state => ({...state, openCopyOrder: false}))}>
            <Alert
                onClose={() => setState(state => ({...state, openCopyOrder: false}))}
                severity="success"
                role="message"
                sx={{width: '100%'}}>
                {t("Activation ID copied")}
            </Alert>
        </Snackbar>
    </PageTemplate>;
}

interface ActivationDetailsState {
    licenseProps: PropData[];
    features: ActivationFeatureItem[];
    signatures: string[];
    propsErrors: boolean;
    notes: string;
    hardwareId: string;
    openCopyActivation: boolean;
}