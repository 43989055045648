import * as React from "react";
import {FunctionComponent, ReactElement, ReactNode, useState} from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {drawerWidth} from "./DrawerHeader";
import {Box, Button, IconButton, useTheme} from "@mui/material";
import {AppbarUserMenu} from "./AppbarUserMenu";
import {LanguageMenu} from "./LanguageMenu";
import {useTranslation} from "react-i18next";
import {AccountCircle} from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useNavigate} from "react-router-dom";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const PageAppBar: FunctionComponent<PageAppBarProps> = props => {
    const {i18n, t} = useTranslation()
    const currentLanguage = i18n.language;
    const navigate = useNavigate();
    const theme = useTheme();
    const [state, setState] = useState<PageAppBarState>({
        langMenuAnchorEl: null,
        userMenuAnchorEl: null
    });
    const langMenuHandleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
        setState(state => ({...state, langMenuAnchorEl: event.currentTarget}));
    const isLangMenuOpen = Boolean(state.langMenuAnchorEl);
    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang)
    }
    const langMenuHandleClose = (languageCode?: string) => {
        if (languageCode) {
            changeLanguage(languageCode);
        }
        setState(state => ({...state, langMenuAnchorEl: null}));
    };

    const isUserMenuOpen = Boolean(state.userMenuAnchorEl);
    const handleUserMenuClose = () =>
        setState(state => ({...state, userMenuAnchorEl: null}));
    const handleUserMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
        setState(state => ({...state, userMenuAnchorEl: event.currentTarget}));
    const buttonProps = {
        title: !props.username ? t("Loading…") : props.username as string,
        onClick: handleUserMenuOpen,
    };

    return <AppBar position="fixed">
        <Toolbar>
            {props.showBackButton && <IconButton
                color="inherit"
                title={props.backLabel ?? ''}
                size="small"
                aria-label={props.backLabel ?? ''}
                onClick={() => {
                    navigate(-1);
                }}>
                <ArrowBackIosIcon/>
            </IconButton>}

            {!props.showBackButton && <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={props.handleDrawerToggle}
                sx={{mr: 2, display: {md: 'none'}}}
            >
                <MenuIcon/>
            </IconButton>}

            <Box component="span"
                 sx={{
                     display: 'flex',
                     flex: 1,
                     maxWidth: 'calc(100% - 170px)',
                     [theme.breakpoints.down('sm')]: {
                         display: 'none'
                     },
                 }}>
                <img src="clim-logo-white.svg" alt="Cloud Licenses Manager" width="30"/>
                <Typography variant="h6" noWrap component="div"
                            sx={{
                                marginLeft: '1em',
                                marginRight: '1em',
                            }}>
                    Cloud Licenses Manager
                </Typography>
            </Box>


            <Box component="span"
                 sx={{
                     display: 'flex',
                     flex: 1,
                     maxWidth: 'calc(100% - 132px)',
                     [theme.breakpoints.up('sm')]: {
                         display: 'none'
                     },
                 }}>
                <Typography variant="h6"
                            color="inherit"
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'inline-block',
                            }}>
                    {props.pageTitle}
                </Typography>
                &nbsp;
                <Typography variant="subtitle1"
                            color="inherit"
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                paddingLeft: '1em',
                                textOverflow: 'ellipsis',
                                display: 'inline-block',
                            }}>
                    {props.pageSubTitle}
                </Typography>
            </Box>

            <Box sx={{
                display: 'flex',
                justifyContent: 'right',
                '& .action-button': {
                    [theme.breakpoints.up('sm')]: {
                        display: 'none'
                    },
                }
            }}>
                <Button color="inherit"
                        className="change-language-menu-button"
                        aria-owns={isLangMenuOpen ? 'lang-menu' : undefined}
                        aria-haspopup="true"
                        onClick={langMenuHandleClick}>
                    <span>{currentLanguage.toUpperCase().substring(0, 2)}</span>
                </Button>
                <LanguageMenu isOpen={isLangMenuOpen}
                              anchorEl={state.langMenuAnchorEl}
                              handleClose={langMenuHandleClose}/>

                {props.actions}

                <Button {...buttonProps} startIcon={<AccountCircle/>} color='inherit'>
                    <Box component="span"
                         sx={{
                             [theme.breakpoints.down('sm')]: {
                                 display: 'none'
                             },
                         }}>
                        {props.username}
                    </Box>
                </Button>
                <AppbarUserMenu isOpen={isUserMenuOpen}
                                anchorEl={state.userMenuAnchorEl}
                                handleClose={handleUserMenuClose}/>
            </Box>
        </Toolbar>
    </AppBar>;
}

export interface PageAppBarProps {
    pageTitle?: ReactNode;
    pageSubTitle?: string;
    username: string | undefined | null;
    handleDrawerToggle: () => void;
    showBackButton?: boolean;
    backLabel?: string;
    actions?: ReactElement[];
}

interface PageAppBarState {
    langMenuAnchorEl: HTMLElement | null;
    userMenuAnchorEl: HTMLElement | null;
}