import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PageTemplate} from "../../common/page-template/PageTemplate";
import {
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
} from "@mui/material";
import {SearchInfo} from "../../common/search-info-grid/SearchInfo";
import {TableSkeleton} from "../../common/skeleton/TableSkeleton";
import SearchIcon from "@mui/icons-material/Search";
import {ErrorMessage} from "../../common/error-message/ErrorMessage";
import {WarningMessage} from "../../common/error-message/WarningMessage";
import {
    OrderItem,
    useGetApiV1UserMeQuery,
    usePostApiV1OrderListMutation,
    useDeleteApiV1OrderMutation,
    usePostApiV1ExportMutation, ExportRequest
} from "../../../api/CloudLicensesManagerApi";
import {Loader} from "../../common/loader/Loader";
import {Authorizations} from "../../../book/Authorizations";
import {OrderTable} from "./OrderTable";
import {DeleteOrderDialog} from "./DeleteOrderDialog";
import {
    ProcessStatusNotification,
    toProcessStatus
} from "../../common/process-status-notification/ProcessStatusNotification";
import GetAppIcon from "@mui/icons-material/GetApp";
import {ExportUtils} from "../../../book/ExportUtils";
import {ActionButton} from "../../common/page-template/ActionButton";

interface OrderListState {
    currentPage: number;
    pageSize: number;
    search: string;
    orderToDelete?: OrderItem;
}

export const OrderList: FunctionComponent = () => {

    const [state, setState] = useState<OrderListState>({
        currentPage: 0,
        pageSize: 50,
        search: ''
    });
    const [ordersSearch, {
        data: ordersSearchData,
        isLoading: isOrdersLoading,
        isError: isOrdersError
    }] = usePostApiV1OrderListMutation();
    const [deleteOrder, {
        data: deleteOrderData,
        isLoading: isDeleteOrderLoading,
        isError: isDeleteOrderError,
        reset: resetDeleteOrder
    }] = useDeleteApiV1OrderMutation();
    const [exportMutation, {
        isLoading: exportIsLoading}] = usePostApiV1ExportMutation();

    const callSearchOrders = () => ordersSearch({
        searchOrdersRequest: {
            search: state.search.length ? state.search : null,
            page: state.currentPage,
            pageSize: state.pageSize
        }
    });
    useEffect(() => {
        callSearchOrders()
    }, [state.search, state.currentPage, state.pageSize]);

    const {t} = useTranslation();
    const {data: userMe, isLoading: isMeLoading} = useGetApiV1UserMeQuery(undefined);

    if (!userMe || isMeLoading) {
        return <Loader message={t("Loading page, please wait...")}/>;
    }

    if (userMe.error) {
        return <ErrorMessage message={t("Error while loading page, please try again later")}/>;
    }

    const authorizations = userMe.payload!.authorizations;

    if (!authorizations!.includes(Authorizations.OrderList)) {
        return <ErrorMessage message={t("You are not authorized to view this page")}/>;
    }

    const orders = ordersSearchData?.payload?.orders ?? [];
    const orderCount = ordersSearchData?.payload?.count ?? 0;

    const OrdersSearchInfoGrid = () => <SearchInfo
        count={orderCount}
        currentPage={state.currentPage}
        pageSize={state.pageSize}
        onPageChange={currentPage => {
            setState({
                ...state,
                currentPage,
            });
        }}
        onPageSizeChange={pageSize => {
            setState({
                ...state,
                pageSize
            });
        }}
        paginationLabel={t("Orders per page")}
        itemsLabel={t("orders")}/>;

    const pageActions: React.ReactElement[] = [];

    if (authorizations?.includes(Authorizations.Export)) {
        pageActions.push(<ActionButton onClick={() => {
            const result = exportMutation({exportRequest: {}});
            result.then(data => {
                if ("data" in data) {
                    const blob = data.data as Blob;
                    ExportUtils.downloadFromBlob(blob, `export_clim_${new Date().toISOString()}.csv`);
                }
            });
        }} disabled={exportIsLoading}
                                       label={exportIsLoading ? t("Exporting…") : t("Export data of all order")}
                                       icon={<GetAppIcon/>}/>);
    }

    return <PageTemplate
        pageTitle={t("Order list")}
        actions={pageActions}>
        <FormControl fullWidth variant="outlined">
            <InputLabel
                htmlFor="search-products">{t("Search by order number, serial number, customer, or department…")}</InputLabel>
            <OutlinedInput value={state.search}
                           fullWidth
                           label={t("Search by order number, serial number, customer, or department…")}
                           onChange={e => {
                               setState(state => ({
                                   ...state,
                                   search: e.target.value,
                               }));
                           }}
                           endAdornment={<InputAdornment position="end"><SearchIcon/></InputAdornment>}/>
        </FormControl>

        {isOrdersError && <ErrorMessage
            message={t("Error while loading orders. Please try again or contact the administrator")}/>}

        {isOrdersLoading &&
            <TableSkeleton sx={{marginTop: '40px'}}
                           colCount={9}
                           colCountMd={8}
                           colCountSm={7}
                           colCountXs={6}
                           rowCount={10}
                           showPagination/>}

        {Boolean(orders.length) && <Stack spacing={3} sx={{marginBottom: '15px', marginTop: '35px'}}>
            <OrdersSearchInfoGrid/>

            <OrderTable orders={orders}
                onDelete={(orderToDelete) => {
                    setState(state => ({...state, orderToDelete}));
                }}/>

            <OrdersSearchInfoGrid/>
            {state.orderToDelete && authorizations!.includes(Authorizations.OrderDelete) &&
            <DeleteOrderDialog onCancelClick={() => {
                                  setState(state => ({
                                      ...state,
                                      orderToDelete: undefined
                                  }));
                              }}
                              onOkClick={(deleteReason?:string|null) => {
                                  if (state.orderToDelete) {
                                      deleteOrder({
                                          deleteOrderRequest: {
                                              orderId: state.orderToDelete.orderId,
                                              deleteReason: deleteReason
                                          }
                                      }).then(() => {
                                          if (state.currentPage !== 0) {
                                              setState(state => ({...state, currentPage: 0}));
                                          } else {
                                            callSearchOrders();
                                          }

                                          resetDeleteOrder();
                                      })
                                      setState(state => ({
                                          ...state,
                                          orderToDelete: undefined
                                      }));
                                  }
                              }}
                              orderToDelete={state.orderToDelete}/>}

        </Stack>}

        {!Boolean(orders.length) && !isOrdersError && !isOrdersLoading &&
            <WarningMessage message="Orders not found"/>}
        <ProcessStatusNotification
            status={toProcessStatus(isDeleteOrderLoading, Boolean(deleteOrderData?.payload), isDeleteOrderError)}
            onClose={() => resetDeleteOrder()}
            onLoadingMessage={t("Deleting order please wait…")}
            onSuccessMessage={t("Order deleted successfully")}
            onErrorMessage={t("Order deleting failed")}/>
    </PageTemplate>;
}