import React, {FunctionComponent, useState} from "react";
import {PageTemplate} from "../../common/page-template/PageTemplate";
import {
    useGetApiV1OrderByOrderIdQuery,
    useGetApiV1UserMeQuery,
    usePostApiV1OfflinePlainLicenseActivationMutation
} from "../../../api/CloudLicensesManagerApi";
import {Link, useNavigate, useParams} from "react-router-dom";
import {ErrorMessage} from "../../common/error-message/ErrorMessage";
import {TableSkeleton} from "../../common/skeleton/TableSkeleton";
import {useTranslation} from "react-i18next";
import {Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import {AppRoute} from "../../../book/AppRoute";
import moment from "moment";
import {
    ProcessStatusNotification,
    toProcessStatus
} from "../../common/process-status-notification/ProcessStatusNotification";
import {DownloadLicenseFile} from "../../common/utils/CreateLicenseFile";
import {Loader} from "../../common/loader/Loader";
import {Authorizations} from "../../../book/Authorizations";

export const OfflineActivation: FunctionComponent = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {orderId} = useParams();
    const [state, setState] = useState<OfflineActivationState>({
        company: "",
        address: "",
        country: "",
        serialNumber: "",
        hardwareId: "",
        expirationDate: moment().add(2, 'y').valueOf()
    });
    const {data: orderData, isLoading: isOrderLoading, isError: isOrderError} = useGetApiV1OrderByOrderIdQuery({
        orderId: orderId as string
    });
    const [createActivation, {
        data: createActivationData,
        isLoading: isCreateActivationLoading,
        error: createActivationError,
        reset: resetCreateActivation
    }] = usePostApiV1OfflinePlainLicenseActivationMutation();
    const {data: userMe, isLoading: isMeLoading} = useGetApiV1UserMeQuery(undefined)

    if (!userMe || isMeLoading) {
        return <Loader message={t("Loading page, please wait...")}/>;
    }

    if (userMe.error) {
        return <ErrorMessage message={t("Error while loading page, please try again later")}/>;
    }

    const authorizations = userMe.payload!.authorizations;
    const order = orderData?.payload?.order;
    const unusedSerialNumbers = orderData?.payload?.unusedSerialNumbers ?? [];

    const formValidation = () => {
        return (
            state.company &&
            state.address &&
            state.country &&
            state.serialNumber &&
            state.hardwareId &&
            state.expirationDate > moment().valueOf()
        );
    };

    if (!authorizations?.includes(Authorizations.ActivationCreate)) {
        return <ErrorMessage message={t("You are not authorized to view this page")}/>;
    }

    return <PageTemplate pageTitle={`Order - ${order?.orderNumber} - Offline activation`}
                         showBackButton>
        {isOrderError &&
            <ErrorMessage message={t("Error while load order, please check order id and retry.")}/>}

        {isOrderLoading &&
            <TableSkeleton colCount={1} rowCount={5}/>}

        <form onSubmit={(e) => {
            createActivation({
                offlineLicenseActivationRequest: {
                    orderId,
                    company: state.company,
                    address: state.address,
                    country: state.country,
                    serialNumber: state.serialNumber,
                    hardwareId: state.hardwareId,
                    expirationDate: state.expirationDate
                }
            }).then(result => {
                if ("data" in result && result.data?.payload?.jwtLicense) {
                    DownloadLicenseFile(result.data.payload.jwtLicense);
                    navigate(-1);
                }
            });

            e.preventDefault();
        }}>

            <Stack spacing={3}>
                <TextField className="value"
                           value={state.company}
                           fullWidth
                           label={t("Company")}
                           onChange={(e) => setState({...state, company: e.target.value})}
                           required/>

                <TextField className="value" value={state.address}
                           fullWidth
                           label={t("Address")}
                           onChange={(e) => setState({...state, address: e.target.value})}
                           required/>

                <TextField className="value" value={state.country}
                           fullWidth
                           label={t("Country")}
                           onChange={(e) => setState({...state, country: e.target.value})}
                           required/>

                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="role-select">{t("Serial number")}</InputLabel>
                    <Select label={t("Serial number")}
                            labelId="role-select"
                            className="value"
                            fullWidth
                            value={state.serialNumber}
                            onChange={(e) => setState({...state, serialNumber: e.target.value})}
                            required>
                        {unusedSerialNumbers.map(sn => <MenuItem key={sn.serialNumber}
                                                                 disabled={sn.isExpired}
                                                                 title={sn.isExpired ? "Expired serial number" : undefined}
                                                                 value={sn.serialNumber}>
                            {sn.serialNumber} - {sn.productName}{sn.isExpired ? ` - ${t('Expired')}` : ''}
                        </MenuItem>)}
                    </Select>
                </FormControl>

                <TextField className="value"
                           value={state.hardwareId}
                           label={t("Hardware ID")}
                           fullWidth
                           onChange={(e) => setState({...state, hardwareId: e.target.value})}
                           required/>

                <TextField className="value" type="datetime-local"
                           value={moment(state.expirationDate).format('YYYY-MM-DDTHH:mm:ss')}
                           label={t("Expiration date")}
                           fullWidth
                           onChange={(e) => {
                               setState({...state, expirationDate: moment(e.target.value).valueOf()});
                           }}/>

                <Stack direction="row" spacing={2} sx={{justifyContent: 'end'}}>
                    <Button
                        variant="contained"

                        component={Link}
                        to={AppRoute.Order.replace(":orderId", orderId!)}>
                        {t("Cancel")}
                    </Button>
                    <Button
                        disabled={!formValidation()}
                        variant="contained"
                        type="submit"
                        color="primary">
                        {t("Create license")}
                    </Button>
                </Stack>
            </Stack>
        </form>

        <ProcessStatusNotification
            status={toProcessStatus(isCreateActivationLoading, Boolean(createActivationData?.payload), Boolean(createActivationError))}
            onClose={() => resetCreateActivation()}
            onLoadingMessage={t("Creating activation please wait…")}
            onSuccessMessage={t("Activation created successfully")}
            onErrorMessage={t("Creating activation failed")}/>
    </PageTemplate>
}

interface OfflineActivationState {
    company: string;
    address: string;
    country: string;
    serialNumber: string;
    hardwareId: string;
    expirationDate: number;
}