import React, {FunctionComponent, useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    SelectChangeEvent
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {usePostApiV1ProductMutation} from "../../../api/CloudLicensesManagerApi";
import {Loader} from "../../common/loader/Loader";

interface InternalSerialNumber {
    product?: string;
    notes?: string;
}

export const CreateInternalSerialNumber: FunctionComponent<CreateInternalSerialNumberProps> = props => {
    const {t} = useTranslation();
    const {open} = props;

    const [state, setState]=useState<InternalSerialNumber>();

    const [productsSearch, {
        data: productsSearchData,
        isLoading: isProductsLoading,
        isError: isProductsError
    }] = usePostApiV1ProductMutation();

    useEffect(() => {
        if (open && !productsSearchData?.payload?.products?.length) {
            productsSearch({
                searchProductsRequest: {
                    search: 'stone'
                }
            });
        }
    }, [productsSearchData, open]);

    return <Dialog maxWidth="sm"
                   aria-labelledby="confirmation-dialog-title"
                   open={props.open}
                   onClose={() => props.onCancelClick()}>
        <DialogTitle id="confirmation-dialog-title">{t("Create serial number (for Carel internal usage)")}</DialogTitle>
        <DialogContent dividers>
            {isProductsLoading && <Loader message={t("Loading products, please wait...")}/>}
            {(!isProductsLoading && productsSearchData?.payload?.products) && <Box>
                <FormControl fullWidth margin='normal'>
                    <InputLabel id="product-label">Product</InputLabel>
                    <Select
                        labelId="product-label"
                        id="product"
                        value={state?.product}
                        onChange={(event: SelectChangeEvent) => {
                            setState({...state, product: event.target.value});
                        }}
                        fullWidth
                        label="Product">
                        <MenuItem value={undefined}>
                            <em>None</em>
                        </MenuItem>
                        {productsSearchData.payload?.products.map(product=><MenuItem value={product.id}>{product.name}</MenuItem>)}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin='normal'>
                    <TextField
                        label="Notes"
                        id="notes" variant="outlined"
                        value={state?.notes}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setState({...state, notes: event.target.value});
                        }}/>
                </FormControl>
            </Box>}
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => {
                    const value = {...state};
                    if(value?.product) {
                        props.onOkClick({ ...value, product: value.product });
                    }
                }}
                color="primary"
                disabled={!Boolean(state?.product)}>

                {t("Create serial number")}
            </Button>
            <Button autoFocus onClick={() => props.onCancelClick()} color="primary">
                {t("Cancel")}
            </Button>
        </DialogActions>
    </Dialog>;
}

export interface CreateInternalSerialNumberProps {
    onOkClick: (data: Required<Pick<InternalSerialNumber,'product'>> & InternalSerialNumber) => void;
    onCancelClick: () => void;
    open: boolean;
}