import moment from "moment";

export class DateUtils {
    public static dateFormat = (date: number, format: string = 'YYYY/MM/DD'): string => {
        return moment(new Date(date)).utc().format(format);
    }

    public static dateTimeFormat = (date: number, format: string = 'YYYY/MM/DD HH:mm:ss'): string => {
        return moment(new Date(date)).utc().format(format);
    }

    public static timeFormat = (date: number, format: string = 'HH:mm:ss'): string => {
        return moment(new Date(date)).utc().format(format);
    }

    public static secondsToYMDHMS(seconds: number | undefined): SecondsToYMDHMSResult {
        if (!seconds) {
            return null;
        }

        const years = Math.floor(seconds / 31536000);
        const days = Math.floor((seconds % 31536000) / 86400);
        const hours = Math.floor((seconds % 86400) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return {
            years,
            days,
            hours: hours,
            minutes: minutes,
            seconds: remainingSeconds
        }
    }
}

export type SecondsToYMDHMSResult =
    { years: number, days: number, hours: number, minutes: number, seconds: number }
    | null;
