import React, {FunctionComponent, ReactNode} from "react";
import {styled} from "@mui/material/styles";
import {Box, Button} from "@mui/material";
import IconButton from "@mui/material/IconButton";

const ResponsiveActionButton = styled(Box)(({theme}) => ({
    '& .page-action': {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    '& .page-action:not(:last-child)':{
        marginRight: '5px'
    },
    '& .app-bar-action': {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
}))

export const ActionButton: FunctionComponent<ActionButtonProps> = props => {
    return <ResponsiveActionButton className="action-button">
        <Button className="page-action"
                variant="contained"
                aria-label={props.label.toLowerCase()}
                color="primary"
                size="small"
                onClick={props.onClick}
                startIcon={props.icon}
                {...(props.disabled && {disabled: props.disabled})}>
            {props.label}
        </Button>

        <IconButton className="app-bar-action"
                    aria-label={props.label.toLowerCase()}
                    onClick={props.onClick}
                    title={props.label}
                    color="inherit">
            {props.icon}
        </IconButton>
    </ResponsiveActionButton>
}

export interface ActionButtonProps {
    label: string;
    onClick: () => void;
    icon: ReactNode;
    disabled?: boolean;
}