import React, {Fragment, FunctionComponent} from "react";
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {AppPagination} from "../app-pagination/AppPagination";

export const SearchInfo: FunctionComponent<SearchInfoGridProps> = props => {
    const {t} = useTranslation();

    return <Grid container>
        <Grid item xs={12} sm={5}>
            <strong>{t("Search result")}</strong>: {props.count} {t(props.itemsLabel ?? 'items')}
            {props.showExecutionTime && Boolean(props.executionTime) && <Fragment>
                &nbsp;(<span data-testid="execution-time" title={t("Execution time")}>{props.executionTime!.toFixed(0)} ms</span>)
            </Fragment>}
        </Grid>
        <Grid item xs={12} sm={7}>
            <AppPagination
                sx={{textAlign: 'right'}}
                defaultPage={props.currentPage + 1}
                onPageChange={props.onPageChange}
                onPageSizeChange={props.onPageSizeChange}
                pageCount={Math.ceil(props.count / props.pageSize)}
                pageSize={props.pageSize}
                label={props.paginationLabel}
                data-testId="app-pagination"
            />
        </Grid>
    </Grid>
};

export interface SearchInfoGridProps {
    executionTime?: number;
    count: number;
    currentPage: number;
    pageSize: number;
    onPageChange: (page: number) => void;
    onPageSizeChange: (page: number) => void;
    showExecutionTime?: boolean;
    paginationLabel?: string;
    itemsLabel?: string;
}