import React, {Fragment, FunctionComponent, useEffect, useState} from "react";
import {PageTemplate} from "../../common/page-template/PageTemplate";
import {useTranslation} from "react-i18next";
import {Alert, Box, Snackbar, Stack} from "@mui/material";
import {
    useGetApiV1UserMeQuery,
    usePostApiV1UserActivityLogMutation,
    usePostApiV1UserMutation,
    UserActivityLogType
} from "../../../api/CloudLicensesManagerApi";
import {SearchInfo} from "../../common/search-info-grid/SearchInfo";
import {TableSkeleton} from "../../common/skeleton/TableSkeleton";
import {ErrorMessage} from "../../common/error-message/ErrorMessage";
import {WarningMessage} from "../../common/error-message/WarningMessage";
import {UserActivityLogFilters} from "./UserActivityLogFilters";
import {UserActivityLogTable} from "./UserActivityLogTable";
import {Loader} from "../../common/loader/Loader";
import {Authorizations} from "../../../book/Authorizations";

export const UserActivityLog: FunctionComponent = () => {
    const {t} = useTranslation();
    const [state, setState] = useState<UserActivityLogState>({
        currentPage: 0,
        pageSize: 50,
        userId: '',
        openCopiedToClipboardSnackbar: false,
        operationCode: ""
    });
    const {data: userMe, isLoading: isMeLoading} = useGetApiV1UserMeQuery(undefined)
    const [searchUsers, {
        data: usersData,
        isLoading: isUsersLoading,
        isError: isUsersError
    }] = usePostApiV1UserMutation();
    const [userActivityLogSearch, {
        data: userActivityLogData,
        isLoading: userActivityLogLoading,
        isError: userActivityLogError
    }] = usePostApiV1UserActivityLogMutation();

    const searchResultUserActivity = userActivityLogData?.payload?.logs ?? [];
    const searchResultCount = userActivityLogData?.payload?.count ?? 0;

    useEffect(() => {
        userActivityLogSearch({
            searchUserActivityLogRequest: {
                userId: state.userId.length ? state.userId : null,
                operation: state.operationCode.length ? parseInt(state.operationCode) as UserActivityLogType : undefined,
                page: state.currentPage,
                pageSize: state.pageSize
            }
        });
        searchUsers({searchUsersRequest: {page: 0, pageSize: 100}});
    }, [state.userId, state.operationCode, state.currentPage, state.pageSize]);

    const operations: { name: string, code: UserActivityLogType }[] = [{
        name: t("Edit license"),
        code: 1
    }, {
        name: t("Create STone internal serial number"),
        code: 2
    }, {
        name: t("Delete serial number"),
        code: 3
    }, {
        name: t("Delete activation"),
        code: 4
    }, {
        name: t("Delete product"),
        code: 5
    }, {
        name: t("Delete order"),
        code: 6
    }];

    const handleCloseCopiedToClipboardSnackbar = (event?: React.SyntheticEvent<any> | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setState(state => ({...state, openCopiedToClipboardSnackbar: false}));
    };

    const UserActivityLogSearchInfoGrid = () => <SearchInfo
        count={searchResultCount}
        currentPage={state.currentPage}
        pageSize={state.pageSize}
        onPageChange={page => {
            setState(state => ({...state, currentPage: page}));
        }}
        onPageSizeChange={pageSize => {
            setState(state => ({...state, pageSize}));
        }}
        paginationLabel={t("User activity per page")}
        itemsLabel={t("user activities")}/>;

    if (!userMe || isMeLoading) {
        return <Loader message={t("Loading page, please wait...")}/>;
    }

    if (userMe.error) {
        return <ErrorMessage message={t("Error while loading page, please try again later")}/>;
    }

    const authorizations = userMe.payload!.authorizations;

    if (!authorizations!.includes(Authorizations.UserActivityLogList)) {
        return <ErrorMessage message={t("You are not authorized to view this page")}/>;
    }

    return <PageTemplate pageTitle={t("User activity log")}>
        <UserActivityLogFilters userId={state.userId}
                                operationCode={state.operationCode}
                                onOperationChange={newOperationId => setState(state => ({
                                    ...state,
                                    operationCode: newOperationId ?? "",
                                    currentPage: 0
                                }))}
                                onUserIdChange={newUserId => setState(state => ({
                                    ...state,
                                    userId: newUserId ?? "",
                                    currentPage: 0
                                }))}
                                isUsersError={isUsersError}
                                isUsersLoading={isUsersLoading}
                                users={usersData?.payload?.users ?? []}
                                operations={operations}/>

        {userActivityLogError && <ErrorMessage
            message={t("Error while loading user activity log. Please try again or contact the administrator")}/>}

        {userActivityLogLoading &&
            <TableSkeleton sx={{marginTop: '40px'}}
                           colCount={4}
                           rowCount={10}
                           showPagination/>}

        {!searchResultUserActivity?.length && !userActivityLogLoading && <Box sx={{mt: 2}}>
            <WarningMessage message={t("User activities not found")}/>
        </Box>}

        {Boolean(searchResultUserActivity?.length) && <Stack spacing={3} sx={{marginBottom: '15px', marginTop: '35px'}}>
            <UserActivityLogSearchInfoGrid/>

            <UserActivityLogTable users={userActivityLogData?.payload?.users ?? []}
                                  operations={operations}
                                  userActivities={searchResultUserActivity}
                                  onCopyClick={() => setState(state => ({
                                      ...state,
                                      openCopiedToClipboardSnackbar: true
                                  }))}/>

            <UserActivityLogSearchInfoGrid/>
        </Stack>}

        <Snackbar open={state.openCopiedToClipboardSnackbar} autoHideDuration={4000}
                  onClose={handleCloseCopiedToClipboardSnackbar}>
            <Alert onClose={handleCloseCopiedToClipboardSnackbar} severity="success">
                {t("Log data copied to clipboard")}
            </Alert>
        </Snackbar>
    </PageTemplate>
}

interface UserActivityLogState {
    currentPage: number;
    pageSize: number;
    userId: string;
    openCopiedToClipboardSnackbar: boolean;
    operationCode: string;
}