export class Authorizations {
    public static readonly OrderList = "order.list";
    public static readonly OrderDelete = "order.delete";

    public static readonly SerialNumberDelete = "serialNumber.delete";
    public static readonly SerialNumberList = "serialNumber.list";
    public static readonly SerialNumberCreate = "serialNumber.create";

    public static readonly ActivationCreate = "activation.create";
    public static readonly ActivationEdit = "activation.edit";
    public static readonly ActivationList = "activation.list";
    public static readonly ActivationDelete = "activation.delete";

    public static readonly LicenseDownload = "license.download";

    public static readonly UserList = "user.list";
    public static readonly UserCreate = "user.create";
    public static readonly UserEdit = "user.edit";
    public static readonly UserDelete = "user.delete";

    public static readonly ProductList = "product.list";

    public static readonly UserActivityLogList = "userActivityLog.list";

    public static readonly Export = "export";
}
