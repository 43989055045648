import {FunctionComponent} from "react";
import {Stack, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

export const NotesEditor: FunctionComponent<NotesEditorProp> = props => {
    const { t } = useTranslation();

    return <Stack spacing={1}>
        <Typography variant="h6">
            {t("Internal notes")}
        </Typography>

        <Typography variant="subtitle2">
            {t("These notes are only visible into the portal and are not included in the license file.")}
        </Typography>

        <TextField
            label={t("Notes")}
            maxRows={5}
            minRows={2}
            inputProps={{ maxLength: 65535 }}
            multiline
            value={props.value}
            fullWidth
            onChange={(ev) => {
                props.setValue(ev.target.value);
            }} />
    </Stack>
}

export interface NotesEditorProp {
    value: string;
    setValue: (newValue: string) => void;
}