import React, {FunctionComponent} from "react";
import Typography from "@mui/material/Typography";
import {TableSkeleton} from "../../common/skeleton/TableSkeleton";
import {
    Checkbox,
    FormControlLabel,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import moment from "moment/moment";
import {DateTimePicker} from "@mui/x-date-pickers";
import {useTranslation} from "react-i18next";
import {ActivationFeatureItem} from "../../../api/CloudLicensesManagerApi";

export const FeaturesEditor: FunctionComponent<FeaturesEditorProps> = props => {
    const {t} = useTranslation();

    return <Stack spacing={1}>
        <Typography variant="h6">
            {t("Features")}
        </Typography>

        {props.isLoading && <TableSkeleton colCount={3} rowCount={15}/>}

        {Boolean(props.features.length) && <Table>
            <TableHead>
                <TableRow>
                    <TableCell/>
                    <TableCell>{t("Feature name")}</TableCell>
                    <TableCell colSpan={2}>{t("Expiration date")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.features.map(feature => {
                    return <TableRow key={feature.featureId}>
                        <TableCell>
                            <Checkbox
                                checked={feature.enabled}
                                color="primary"
                                onChange={e => {
                                    const index = props.features.indexOf(feature);
                                    const newFeaturesState = [...props.features];

                                    newFeaturesState[index] = {
                                        ...newFeaturesState[index],
                                        enabled: e.target.checked
                                    };

                                    props.onFeaturesChange(newFeaturesState);
                                }}
                            />
                        </TableCell>
                        <TableCell>{feature.name}</TableCell>
                        <TableCell>
                            {feature.enabled && <FormControlLabel
                                control={
                                    <Switch
                                        checked={!feature.expirationDate}
                                        onChange={e => {
                                            const index = props.features.indexOf(feature);
                                            const newFeaturesState = [...props.features];

                                            newFeaturesState[index] = {
                                                ...newFeaturesState[index],
                                                expirationDate: e.target.checked ? null : newFeaturesState[index].expirationDate ?? moment().add(6, 'month').valueOf()
                                            };

                                            props.onFeaturesChange(newFeaturesState);
                                        }}
                                        color="primary"
                                    />}
                                label={t("Unlimited")}
                            />}
                        </TableCell>
                        <TableCell>
                            {feature.expirationDate && feature.enabled &&
                                <DateTimePicker label={t("Expiration date")}
                                                format="YYYY/MM/DD HH:mm:ss"
                                                value={moment(new Date(feature.expirationDate))}
                                                onChange={date => {
                                                    const index = props.features.indexOf(feature);
                                                    const newFeaturesState = [...props.features];

                                                    newFeaturesState[index] = {
                                                        ...newFeaturesState[index],
                                                        expirationDate: moment(date).valueOf()
                                                    };

                                                    props.onFeaturesChange(newFeaturesState);
                                                }}/>
                            }
                        </TableCell>
                    </TableRow>;
                })}
            </TableBody>
        </Table>}
    </Stack>
}

export interface FeaturesEditorProps {
    features: ActivationFeatureItem[];
    isLoading: boolean;
    onFeaturesChange: (features: ActivationFeatureItem[]) => void;
}