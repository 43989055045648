import Dialog from "@mui/material/Dialog";
import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import {OrderItem} from "../../../api/CloudLicensesManagerApi";

export const DeleteOrderDialog: FunctionComponent<DeleteOrderProps> = props => {
    const {t} = useTranslation();
    const {onCancelClick, onOkClick, orderToDelete} = props;
    const [state, setState] = useState<DeletOrderState>({
        deleteReason: null 
    })
    return <Dialog
        maxWidth="sm"
        aria-labelledby="confirmation-dialog-title"
        onClose={() => props.onCancelClick()}
        open={true}>
        <DialogTitle id="confirmation-dialog-title">{t("Delete order")}</DialogTitle>
        <DialogContent dividers>
            <Typography>Are you sure you want to delete order : {orderToDelete.orderNumber}?</Typography>
            <Typography marginBottom={'1em'}>The order will no longer appear in the list. Please provide a reason for deleting the order.</Typography>
            <TextField label="Reason"
                       variant="outlined"
                       style={{width : '90%'}}
                       value={state?.deleteReason ?? ''}
                       sx={{width: '300px', marginRight: '1em'}}
                       onChange={e => setState(state => ({...state, deleteReason: e.target.value as string}))}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onOkClick(state.deleteReason)} color="primary">
                {t("Ok")}
            </Button>
            <Button autoFocus onClick={() => onCancelClick()} color="primary">
                {t("Cancel")}
            </Button>
        </DialogActions>
    </Dialog>;
}

export interface DeleteOrderProps {
    onCancelClick: () => void;
    onOkClick: (deleteReason?: string|null) => void;
    orderToDelete: OrderItem;
}

interface DeletOrderState {
    deleteReason?: string | null;
}