import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, {Fragment, FunctionComponent, memo, useState} from "react";
import {useTranslation} from "react-i18next";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import {DateUtils} from "../../../book/DateUtils";
import {Alert, Box, Snackbar, useTheme} from "@mui/material";
import {UserRole, UserRoleUtils} from "../../../book/UserRoleUtils";
import {UserData} from "../../../api/CloudLicensesManagerApi";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface UsersTableState {
    openCopyEmail: boolean;
}

const InternalUsersTable: FunctionComponent<UsersTableProps> = props => {
    const {t} = useTranslation();
    const theme = useTheme();
    const [state, setState] = useState<UsersTableState>({openCopyEmail: false});

    return <Fragment>
        <Table aria-label={t("users")}>
            <TableHead>
                <TableRow>
                    <TableCell>{t("Email")}</TableCell>
                    <TableCell/>
                    <TableCell sx={{[theme.breakpoints.down('sm')]: {display: 'none'}}}>
                        {t("Role")}
                    </TableCell>
                    <TableCell sx={{[theme.breakpoints.down('lg')]: {display: 'none'}}}>
                        {t("Created")}
                    </TableCell>
                    <TableCell sx={{[theme.breakpoints.down('sm')]: {display: 'none'}}}>
                        {t("Last change")}
                    </TableCell>
                    <TableCell/>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.users.map(user => (
                    <TableRow key={user.id}>
                        <TableCell scope="row">
                            {user.username}
                        </TableCell>
                        <TableCell>
                            {user.username && <IconButton title={t("Copy email")}
                                                          onClick={async () => {
                                                              setState(state => ({...state, openCopyEmail: true}));
                                                              await navigator.clipboard.writeText(user.username!);
                                                          }}>
                                <ContentCopyIcon fontSize="small"/>
                            </IconButton>}
                        </TableCell>
                        <TableCell sx={{[theme.breakpoints.down('sm')]: {display: 'none'}}}>
                            {t(user.role as string)}
                        </TableCell>
                        <TableCell sx={{[theme.breakpoints.down('lg')]: {display: 'none'}}}>
                            <Box component="span">{DateUtils.dateFormat(user.inserted)}</Box>
                            <Box component="span" sx={{
                                display: {
                                    md: 'none',
                                    lg: 'inline-block'
                                }
                            }}>&nbsp;{DateUtils.timeFormat(user.inserted)}</Box>
                        </TableCell>
                        <TableCell sx={{[theme.breakpoints.down('sm')]: {display: 'none'}}}>
                            <Box component="span">{DateUtils.dateFormat(user.updated)}</Box>
                            <Box component="span" sx={{
                                display: {
                                    md: 'none',
                                    lg: 'inline-block'
                                }
                            }}>&nbsp;{DateUtils.timeFormat(user.updated)}</Box>
                        </TableCell>
                        <TableCell align="right">
                            <IconButton aria-label={t("Edit").toLowerCase()}
                                        title={t("Edit")}
                                        color="primary"
                                        onClick={_ => {
                                            props.onEdit(user);
                                        }}>
                                <EditIcon/>
                            </IconButton>
                            {UserRoleUtils.ToRole(user.role) !== UserRole.Admin &&
                                <IconButton aria-label={t("Remove").toLowerCase()}
                                            title={t("Remove")}
                                            color="primary"
                                            onClick={_ => {
                                                props.onDelete(user);
                                            }}>
                                    <DeleteForeverIcon/>
                                </IconButton>}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>

        <Snackbar open={state.openCopyEmail}
                  autoHideDuration={3000}
                  onClose={() => setState(state => ({...state, openCopyEmail: false}))}>
            <Alert
                onClose={() => setState(state => ({...state, openCopyEmail: false}))}
                severity="success"
                role="message"
                sx={{width: '100%'}}>
                {t("Email copied")}
            </Alert>
        </Snackbar>
    </Fragment>;
}

export const UsersTable = memo(InternalUsersTable, (prev, next) => {
    return !!prev && prev.users === next.users;
});

export interface UsersTableProps {
    onDelete: (user: UserData) => void;
    onEdit: (user: UserData) => void;
    users: UserData[];
}