import * as React from 'react';
import {FunctionComponent, PropsWithChildren, ReactNode, useEffect} from 'react';
import Box from '@mui/material/Box';
import {AppDrawer} from "./AppDrawer";
import {PageAppBar} from "./PageAppBar";
import {Divider, useTheme} from "@mui/material";
import {UserUtils} from "../../../book/UserUtils";
import {AppRoute} from "../../../book/AppRoute";
import {useNavigate} from "react-router-dom";
import {AppState, useAppDispatch, useAppSelector} from "../../../store/AppStore";
import {EnvironmentSticker} from "./EnvironmentSticker";
import {userLogoutAction} from '../../../action/UserLogoutAction';
import {Footer} from "./Footer";
import {Title} from "./Title";
import {Children} from "./Children";
import {styled} from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import {useGetApiV1UserMeQuery} from "../../../api/CloudLicensesManagerApi";
import {UserRoleUtils} from '../../../book/UserRoleUtils';

const Main = styled(Box)(({theme}) => ({
    flexGrow: 1,
    padding: '1em',
    marginBottom: '75px',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
        padding: '1em 0em',
    },
}))

export const PageTemplate: FunctionComponent<PropsWithChildren<PageTemplateProps>> = props => {
    const tokenExpirationDate = useAppSelector((appState: AppState) => (appState.userState.tokenExpirationDate));
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const {data: userMe} = useGetApiV1UserMeQuery(undefined)
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        if (!UserUtils.isLoggedIn(tokenExpirationDate)) {
            dispatch(userLogoutAction());
            navigate(AppRoute.Login);
        }
    }, [tokenExpirationDate])

    return <Box sx={{display: 'flex'}}>
        <PageAppBar username={userMe?.payload?.username}
                    handleDrawerToggle={handleDrawerToggle}
                    showBackButton={props.showBackButton}
                    backLabel={props.backLabel}
                    pageTitle={props.pageTitle}
                    pageSubTitle={props.pageSubTitle}
                    actions={props.actions}/>

        <AppDrawer userRole={UserRoleUtils.ToRole(userMe?.payload?.role)}
                   handleDrawerToggle={handleDrawerToggle}
                   isMobileOpen={mobileOpen}/>

        <EnvironmentSticker/>

        <Main component="main">
            <Toolbar/>

            <Box sx={{
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                },
            }}>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    '& .action-button': {
                        [theme.breakpoints.down('sm')]: {
                            display: 'none'
                        },
                    }
                }}>
                    <Box sx={{flexGrow: 1}}>
                        {props.pageTitle &&
                            <Title variant='h5'>
                                {props.pageTitle}
                            </Title>}
                        &nbsp;
                        {props.pageSubTitle &&
                            <Title variant='subtitle1'>{props.pageSubTitle}</Title>}
                    </Box>

                    <Box sx={{display: 'flex', flexWrap: 'nowrap'}}>
                        {props.actions}
                    </Box>
                </Box>

                <Divider/>
            </Box>

            <Children>
                {props.children}
            </Children>
        </Main>

        <Footer>
            <div>© CAREL INDUSTRIES S.p.A- Registration number: 04359090281 in Padova Business Register - All rights
                reserved
            </div>
            <a href="https://www.carel.com" target="_blank">&nbsp;</a>
        </Footer>
    </Box>;
}

export interface PageTemplateProps {
    pageTitle?: ReactNode;
    pageSubTitle?: string;
    showBackButton?: boolean;
    backLabel?: string;
    actions?: React.ReactElement[];
}